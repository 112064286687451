export interface SpritesMap {
  sprite:
    | 'add'
    | 'attechment'
    | 'attention'
    | 'automation'
    | 'blockchain'
    | 'box'
    | 'brainstorm'
    | 'burger'
    | 'calendar'
    | 'case'
    | 'check'
    | 'clock'
    | 'coins'
    | 'copy'
    | 'cross-fancy'
    | 'cross'
    | 'crowdfunding'
    | 'current-order'
    | 'cursor'
    | 'delete'
    | 'destination'
    | 'dots'
    | 'dribbble-text'
    | 'e-commerce'
    | 'edit'
    | 'event'
    | 'favorite'
    | 'filter-mob-two'
    | 'fire'
    | 'flash'
    | 'flter-mob'
    | 'globe'
    | 'history-orders'
    | 'honor-board'
    | 'how-we-work'
    | 'info'
    | 'insurance'
    | 'keyboard'
    | 'laptop'
    | 'list'
    | 'location'
    | 'mail'
    | 'map'
    | 'mcn'
    | 'menu'
    | 'minus'
    | 'mobile-development'
    | 'mobile'
    | 'network'
    | 'notification'
    | 'office-1'
    | 'office-2'
    | 'online-shop'
    | 'order-tracking'
    | 'our-products'
    | 'our-team'
    | 'partner-with-us'
    | 'phone'
    | 'plus'
    | 'pos'
    | 'qality-testing'
    | 'question-two'
    | 'question'
    | 'quotes'
    | 'restaurant'
    | 'rules'
    | 'schedule'
    | 'search'
    | 'settings'
    | 'share'
    | 'shopping-bag'
    | 'smart'
    | 'star'
    | 'stores'
    | 'suitcase'
    | 'table'
    | 'text-bubble'
    | 'travel-hospitality'
    | 'user-profile'
    | 'user'
    | 'ux-ui-design'
    | 'web-development'
  arrows:
    | 'arrow-back'
    | 'arrow-down'
    | 'arrow-left'
    | 'arrow-outside'
    | 'arrow-right'
    | 'arrow-up'
    | 'back'
    | 'caret-down'
    | 'caret-left'
    | 'caret-right'
    | 'caret-up'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'clear'
    | 'dribbble-arrow'
    | 'expand'
    | 'export'
    | 'logout'
    | 'sort-marked'
    | 'sort'
    | 'timer'
    | 'upload'
  options:
    | 'exclude-1'
    | 'exclude-10'
    | 'exclude-11'
    | 'exclude-12'
    | 'exclude-13'
    | 'exclude-14'
    | 'exclude-15'
    | 'exclude-16'
    | 'exclude-17'
    | 'exclude-18'
    | 'exclude-19'
    | 'exclude-2'
    | 'exclude-20'
    | 'exclude-21'
    | 'exclude-22'
    | 'exclude-23'
    | 'exclude-24'
    | 'exclude-25'
    | 'exclude-26'
    | 'exclude-27'
    | 'exclude-28'
    | 'exclude-29'
    | 'exclude-3'
    | 'exclude-30'
    | 'exclude-31'
    | 'exclude-32'
    | 'exclude-33'
    | 'exclude-34'
    | 'exclude-35'
    | 'exclude-36'
    | 'exclude-37'
    | 'exclude-38'
    | 'exclude-39'
    | 'exclude-4'
    | 'exclude-40'
    | 'exclude-41'
    | 'exclude-42'
    | 'exclude-43'
    | 'exclude-44'
    | 'exclude-45'
    | 'exclude-46'
    | 'exclude-47'
    | 'exclude-48'
    | 'exclude-49'
    | 'exclude-5'
    | 'exclude-50'
    | 'exclude-51'
    | 'exclude-52'
    | 'exclude-53'
    | 'exclude-54'
    | 'exclude-55'
    | 'exclude-56'
    | 'exclude-6'
    | 'exclude-7'
    | 'exclude-8'
    | 'exclude-9'
  social:
    | 'behance'
    | 'dribbble'
    | 'facebook'
    | 'github'
    | 'instagram'
    | 'linkedin'
    | 'medium'
    | 'twitter'
    | 'youtube'
}
export const SPRITES_META: {
  [Key in keyof SpritesMap]: {
    filePath: string
    items: Record<
      SpritesMap[Key],
      {
        viewBox: string
        width: number
        height: number
      }
    >
  }
} = {
  sprite: {
    filePath: 'sprite.82a5fa55.svg',
    items: {
      add: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      attechment: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      attention: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      automation: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      blockchain: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      box: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      brainstorm: {
        viewBox: '0 0 16 18',
        width: 16,
        height: 18,
      },
      burger: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      calendar: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      case: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      check: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      clock: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      coins: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      copy: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'cross-fancy': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      cross: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      crowdfunding: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'current-order': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      cursor: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      delete: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      destination: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      dots: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'dribbble-text': {
        viewBox: '0 0 88 25',
        width: 88,
        height: 25,
      },
      'e-commerce': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      edit: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      event: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      favorite: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'filter-mob-two': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      fire: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      flash: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'flter-mob': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      globe: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'history-orders': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'honor-board': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'how-we-work': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      info: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      insurance: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      keyboard: {
        viewBox: '0 0 18 12',
        width: 18,
        height: 12,
      },
      laptop: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      list: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      location: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      mail: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      map: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      mcn: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      menu: {
        viewBox: '0 0 22 20',
        width: 22,
        height: 20,
      },
      minus: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'mobile-development': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      mobile: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      network: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      notification: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'office-1': {
        viewBox: '0 0 43 44',
        width: 43,
        height: 44,
      },
      'office-2': {
        viewBox: '0 0 43 44',
        width: 43,
        height: 44,
      },
      'online-shop': {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      'order-tracking': {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      'our-products': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'our-team': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'partner-with-us': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      phone: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      plus: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      pos: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      'qality-testing': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      'question-two': {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      question: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      quotes: {
        viewBox: '0 0 35 27',
        width: 35,
        height: 27,
      },
      restaurant: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      rules: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      schedule: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      search: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      settings: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      share: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'shopping-bag': {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      smart: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      star: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      stores: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      suitcase: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      table: {
        viewBox: '0 0 16 14',
        width: 16,
        height: 14,
      },
      'text-bubble': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'travel-hospitality': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'user-profile': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      user: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'ux-ui-design': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      'web-development': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
    },
  },
  arrows: {
    filePath: 'arrows.d259dee3.svg',
    items: {
      'arrow-back': {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      'arrow-down': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-left': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-outside': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-right': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-up': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      back: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-down': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-left': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-right': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-up': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-down': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-left': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-right': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-up': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      clear: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'dribbble-arrow': {
        viewBox: '0 0 23 23',
        width: 23,
        height: 23,
      },
      expand: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      export: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      logout: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'sort-marked': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      sort: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      timer: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      upload: {
        viewBox: '0 0 15 16',
        width: 15,
        height: 16,
      },
    },
  },
  options: {
    filePath: 'options.92d662bc.svg',
    items: {
      'exclude-1': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-10': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-11': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-12': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-13': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-14': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-15': {
        viewBox: '0 0 30 30',
        width: 30,
        height: 30,
      },
      'exclude-16': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-17': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-18': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-19': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-2': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-20': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-21': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-22': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-23': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-24': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-25': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-26': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-27': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-28': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-29': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-3': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-30': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-31': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-32': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-33': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-34': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-35': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-36': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-37': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-38': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-39': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-4': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-40': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-41': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-42': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-43': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-44': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-45': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-46': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-47': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-48': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-49': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-5': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-50': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-51': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-52': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-53': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-54': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-55': {
        viewBox: '0 0 30 31',
        width: 30,
        height: 31,
      },
      'exclude-56': {
        viewBox: '0 0 30 30',
        width: 30,
        height: 30,
      },
      'exclude-6': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-7': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-8': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
      'exclude-9': {
        viewBox: '0 0 31 30',
        width: 31,
        height: 30,
      },
    },
  },
  social: {
    filePath: 'social.5945f325.svg',
    items: {
      behance: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      dribbble: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      facebook: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      github: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      instagram: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      linkedin: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      medium: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      twitter: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      youtube: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
    },
  },
}
